"use strict";

import Cookies from "js-cookie";

$(function () {
    const showPromotionsModal = function () {
        const modalElement = $(".js-promotions-modal");
        modalElement.modal().on("shown.bs.modal", function () {
            const cookieData = modalElement.data("disable-modal-cookie-data");
            Cookies.set(cookieData.name, cookieData.value, cookieData.options);
        });
    }
    setTimeout(showPromotionsModal, 5000);
});
